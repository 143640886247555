/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Breadcrumb from 'components/shared/Breadcrumb'
import GravityForm from 'components/GravityForm'

// Third Party
import styled from 'styled-components'

const StyledHero = styled(Hero)`
  margin-bottom: 90px;

  @media screen and (max-width: 991px) {
    margin-bottom: unset;
  }
`

const FormWrapper = styled.div`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...ContactFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { title, path, yoast_meta: yoast, acf },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />
      
      <StyledHero content={acf.banner} contact={acf.info} />

      <Breadcrumb data={title} className="pt-5" pageContext={pageContext} />

      <div className="container py-5 px-lg-5 px-4">
        <FormWrapper className="p-lg-5 p-3">
          <GravityForm enableConsent id={1} />
        </FormWrapper>
      </div>
    </Layout>
  )
}

export default PageTemplate
